import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./movie-teller.module.scss"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"

const cubeItems1 = [
  "projects.movie-teller.technology-background.paragraph-3-list.first-text",
  "projects.movie-teller.technology-background.paragraph-3-list.second-text",
  "projects.movie-teller.technology-background.paragraph-3-list.third-text",
  "projects.movie-teller.technology-background.paragraph-3-list.forth-text",
  "projects.movie-teller.technology-background.paragraph-3-list.fifth-text",
  "projects.movie-teller.technology-background.paragraph-3-list.sixth-text",
]

const cubeItems2 = [
  "projects.movie-teller.technology-background.paragraph-4-list.first-text",
  "projects.movie-teller.technology-background.paragraph-4-list.second-text",
  "projects.movie-teller.technology-background.paragraph-4-list.third-text",
]

const MovieTeller: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const challengeClasses = classNames(
    componentStyles.challenge,
    "container"
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title",
    "section-title-h2"
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title",
    "section-title-h2"
  )

  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title",
    "section-title-h2"
  )
  const technologyBackgroundTextListClasses = classNames(
    componentStyles.technologyBackgroundTextList,
    "grid-2"
  )

  const resultsClasses = classNames(componentStyles.results, "container")
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title",
    "section-title-h2"
  )
  const projectsTitleClasses = classNames(
    "large-section-title",
    "section-title-h2"
  )
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const blockWithBackground = classNames(
    projectsStyles.blockPreviewItem,
    projectsStyles.backgroundBlock
  )
  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.movie-teller.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.movie-teller.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.movie-teller.banner.title",
              })}
            </h1>
          </div>
        </div>
      </section>
      <section className="container">
        <div className={componentStyles.clientCompany}>
          <h2 className="large-section-title section-title-h2">
            {intl.formatMessage({
              id: "projects.movie-teller.banner.client-company",
            })}
          </h2>
          <div className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.movie-teller.banner.client-name",
            })}
          </div>
        </div>
      </section>
      <section className={challengeClasses}>
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={challengeTitleClasses}>
            {intl.formatMessage({
              id: "projects.movie-teller.challenge.title",
            })}
          </h2>
          <p className={componentStyles.challengeText}>
            {intl.formatMessage({
              id: "projects.movie-teller.challenge.paragraph-1",
            })}
          </p>
        </div>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.movie-teller.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.movie-teller.solution-description.text",
            })}
          </p>
        </div>
      </section>
      <section className={technologyBackgroundClasses}>
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
            id: "projects.movie-teller.technology-background.title",
          })}
        </h2>
        <p className={componentStyles.technologyBackgroundText}>
          {intl.formatMessage({
            id: "projects.movie-teller.technology-background.text-1",
          })}
        </p>
        <p className={componentStyles.technologyBackgroundText}>
          {intl.formatMessage({
            id: "projects.movie-teller.technology-background.text-2",
          })}
        </p>
        <ul className={technologyBackgroundTextListClasses}>
          <li className={componentStyles.technologyBackgroundTextItem}>
            {intl.formatMessage({
              id: "projects.movie-teller.technology-background.paragraph-1",
            })}
          </li>
          <li className={componentStyles.technologyBackgroundTextItem}>
            {intl.formatMessage({
              id: "projects.movie-teller.technology-background.paragraph-2",
            })}
          </li>
          <li className={componentStyles.technologyBackgroundTextItem}>
            {intl.formatMessage({
              id: "projects.movie-teller.technology-background.paragraph-3",
            })}
            <ul className={componentStyles.technologyBackgroundCubeList}>
              {cubeItems1.map(translatePath => (
                <li
                  key={translatePath}
                  className={componentStyles.technologyBackgroundCubeItem}
                >
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </li>
              ))}
            </ul>
          </li>
          <li className={componentStyles.technologyBackgroundTextItem}>
            {intl.formatMessage({
              id: "projects.movie-teller.technology-background.paragraph-4",
            })}
            <ul className={componentStyles.technologyBackgroundCubeList}>
              {cubeItems2.map(translatePath => (
                <li
                  key={translatePath}
                  className={componentStyles.technologyBackgroundCubeItem}
                >
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.movie-teller.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.movie-teller.results.text",
          })}
        </p>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link, banner, textColor }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                banner={banner}
                backgroundSize="cover"
                rightBlockStyle="none"
                className={blockWithBackground}
                textColor={textColor}
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout>
  )
}
export default MovieTeller
